<template>
    <div class="right-table">
        <div class="head">
            <a-checkbox
                :disabled="disabled || allTreeIsDisabled"
                :checked="allTreeIsChecked"
                @change="allTreeChange"
            />
            <span class="head-title">已选合集({{ dataList.length }}项)</span>
        </div>
        <div class="content">
            <a-input-search
                style="margin-bottom: 8px"
                placeholder="请输入关键词选择"
                @search="onChange"
            />
            <custom-no-data
                v-if="!dataList.length"
                text="请选择待选清单"
                class="no-data-custom"
            />
            <a-table
                v-else
                :row-selection="
                    disabled
                        ? null
                        : {
                            selectedRowKeys: selectedRowKeys,
                            onChange: onSelectChange,
                        }
                "
                :scroll="{
                    y: 330,
                }"
                :columns="columns"
                :data-source="dataList"
                :pagination="{
                    hideOnSinglePage: true,
                    simple: true,
                }"
                row-key="id"
            >
                <template slot="parentName" slot-scope="text, item">
                    <span v-if="item.parentName.indexOf(searchValue) > -1">
                        {{
                            item.parentName.substr(
                                0,
                                item.parentName.indexOf(searchValue)
                            )
                        }}
                        <span style="color: #f50; float: left">{{
                            searchValue
                        }}</span>
                        {{
                            item.parentName.substr(
                                item.parentName.indexOf(searchValue) +
                                    searchValue.length
                            )
                        }}
                    </span>
                    <span v-else>{{ item.parentName }}</span>
                </template>
                <template slot="dataName" slot-scope="dataName">
                    <span v-if="dataName.indexOf(searchValue) > -1">
                        {{ dataName.substr(0, dataName.indexOf(searchValue)) }}
                        <span style="color: #f50; float: left">{{
                            searchValue
                        }}</span>
                        {{
                            dataName.substr(
                                dataName.indexOf(searchValue) +
                                    searchValue.length
                            )
                        }}
                    </span>
                    <span v-else>{{ dataName }}</span>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { Input, Table, Checkbox } from 'ant-design-vue'
import CustomNoData from '@components/CustomNoData'

const columns = [
    {
        title: '一级',
        dataIndex: 'parentName',
        scopedSlots: {
            customRender: 'parentName'
        }
    },
    {
        title: '二级',
        dataIndex: 'dataName',
        scopedSlots: {
            customRender: 'dataName'
        }
    }
]
export default {
    name: 'TreeTable',
    dataName: 'TreeTable',
    components: {
        ATable: Table,
        AInputSearch: Input.Search,
        CustomNoData,
        ACheckbox: Checkbox
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedRowKeys: [],
            searchValue: ''
        }
    },
    computed: {
        columns() {
            return columns
        },
        dataList() {
            const list2 = JSON.parse(JSON.stringify(this.list)) || []
            return list2
                .sort((a, b) => a.sort - b.sort)
                .filter(
                    item => item.dataName.includes(this.searchValue) ||
                        item.parentName.includes(this.searchValue)
                )
        },
        allTreeIsDisabled() {
            return !this.list?.length
        },
        allTreeIsChecked() {
            return !!(
                this.list?.length &&
                this.selectedRowKeys.length === this.list.length
            )
        }
    },
    methods: {
        onSelectChange(selectedRowKeys) {
            this.$emit('select', selectedRowKeys)
            this.selectedRowKeys = selectedRowKeys
        },
        onChange(v) {
            this.searchValue = v
        },
        allTreeChange(e) {
            const checked = e.target.checked
            this.selectedRowKeys = checked ? this.list.map(item => item.id) : []
            this.$emit('select', this.selectedRowKeys)
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.right-table {
    width: 550px;
    height: 538px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #dcdee0;

    .head {
        padding: 6px 12px;
        border-bottom: 1px solid #dcdee0;
    }

    .head-title {
        width: 120px;
        height: 20px;
        font-size: $font-size;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323233;
        line-height: 20px;
        margin-left: 6px;
    }

    .content {
        padding: 8px 12px;

        .no-data-custom {
            margin-top: 109px;
        }
    }
}
</style>
